body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.MuiDialogContent-root form {
  padding-bottom: 100px;
}

.MuiPaper-root {
  color: black !important;
}
header {
  background-color: #f1a22a !important;
}
.Dashboard-inputInput-14 {
  color: black !important;
}
.MuiIconButton-colorPrimary {
  color: #f1a22a !important;
}
.MuiIconButton-colorSecondary {
  color: #038235 !important;
}
.MuiList-root {
  color: black !important;
}
.MuiCircularProgress-colorPrimary {
  color: #038235 !important;
}
th {
  color: #038235 !important;
}
td {
  text-align: center;
}

.pivoted{
    padding: 1em;
}

.App {
  text-align: center;
}

.App-logo {
  /*animation: App-logo-spin infinite 20s linear;*/
  height: 10vmin;
  pointer-events: none;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@-webkit-keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

